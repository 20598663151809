<template>
  <div class="content-wrapper">
    <div class="work main-content">

      <div class="page-header">
        <h1>My Related Work</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Work Name" @search="searchWork" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Status">
                <el-select v-model="searchForm.status" placeholder="Please select status">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Priority" class="priority-field">
                <el-select v-model="searchForm.priority" placeholder="Please select priority">
                  <el-option
                    v-for="item in priorityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
      </div>

      <div class="work-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Work Name</th>
              <th scope="col">Status</th>
              <th scope="col">Priority</th>
              <th scope="col">Deadline</th>
              <th scope="col">Create Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="work in works">
              <td>{{ work.work_name }}</td>
              <td>{{ work.status }}</td>
              <td>{{ work.priority }}</td>
              <td>{{ formatDateTime(work.deadline_start, 'YYYY-MM-DD HH:mm') }} - {{ formatDateTime(work.deadline_end, 'YYYY-MM-DD HH:mm') }}</td>
              <td>{{ formatDateTime(work.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="action">
                <router-link :to="{name:'Work', params: {id: work.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteWork(work.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Work from '@/lib/work';

export default {
  name: 'MyWork',
  components: {
    ItemFilter,
  },
  data(){
    return {
      works: [],
      originWorks: [],
      searchForm: {
        'status': '',
        'priority': '',
      },
      statusOptions:[
        {'label': 'Pending', 'value': 'Pending'},
        {'label': 'Processing', 'value': 'Processing'},
        {'label': 'Canceled', 'value': 'Canceled'},
        {'label': 'Completed', 'value': 'Completed'}
      ],
      priorityOptions: [
        {'label': 'High', 'value': 'High'},
        {'label': 'Medium', 'value': 'Medium'},
        {'label': 'Low', 'value': 'Low'},
      ],
    }
  },
  mounted(){
    this.loadUserWorks();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.works = this.originWorks;
    },
    searchWork(textSearchValue){
      this.searchForm['work_name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newWorks = Common.filterItems(searchFilter, this.originWorks);
      this.works = newWorks;
    },
    async loadUserWorks(){
      const loginInfo = Common.getLoginInfo();
      try{
        const works = await Work.loadUserWorks(this.apiUrl, loginInfo);
        this.originWorks = works;
        this.works = works;
      }catch(err){
        console.log(err);
      }
    },
    async deleteWork(workId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Work.deleteWork(this.apiUrl, workId, loginInfo);
        const newWorks = this.works.filter(function(item) {
          return item.id !== workId;
        });
        this.works = newWorks;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.work-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
